<template>
  <div class="homeAddress">
    <city-select v-bind="$attrs" v-on="$listeners"></city-select>
    <el-input v-model="inputValue" class="input"></el-input>
    <el-switch
      v-model="switchData"
      :active-color="'#6996FF'"
      :inactive-color="'#dcdfe6'"
      active-value="1"
      inactive-value="0">
    </el-switch>
  </div>
</template>
<script>
import CitySelect from './city-select.vue'
export default {
  data () {
    return {
      inputValue: '',
      switchData: false
    }
  },
  components: {
    CitySelect
  }
}
</script>
<style lang="scss" scoped>
.homeAddress {
  display: flex;
  align-items: center;
  .input {
    flex: 1;
    margin: 0px 20px;
  }
}
</style>
