<template>
  <el-cascader
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
    :props='props'
    ref="cascader"
    @change="handleChange"
    clearable
  ></el-cascader>
</template>
<script>

export default {
  name: 'supplierCascader',
  data () {
    return {
      value: [],
      options: [],
      city: [],
      province: [],
      props: {
        value: 'dictName',
        name: 'dictName'
      }
    }
  },
  props: {
    // 数据对象
    addressvalue: {
      type: Object,
      default: () => {
      }
    },
    // 地址数据
    address: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    addressvalue (val, val1) {
      this.getData()
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    /**
     * 自定义事件
     * @param value
     */
    handleChange (value) {
      this.$emit('input', value)
    },
    /**
     * 数据初始换
     * @returns {boolean}
     */
    getData () {
      this.province = this.address
      if (!this.province) {
        return false
      }

      this.options = []

      // 组合选项数据
      this.province.forEach((item) => {
        if (item.pid === '0') {
          this.options.push({
            label: item.dictName,
            value: item.dictId,
            children: [],
            ...item
          })
        }
      })
      this.options.forEach((item, index, arr) => {
        this.province.forEach((elem) => {
          if (elem.pid === item.value) {
            const obj = {
              label: elem.dictName,
              value: elem.dictId,
              children: [],
              ...elem
            }
            arr[index].children.push(obj)
          }
        })
      })
      // 回填渲染
      if (this.addressvalue) {
        const province = this.address.find(item => item.dictName === this.addressvalue.manageProvince)
        const city = this.address.find(item => item.dictName === this.addressvalue.manageCity)
        if (province || city) this.value = [province.dictId, city.dictId]
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
